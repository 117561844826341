import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
Vue.prototype.$MAP_LOADED = false;

var app = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
window.initMap = function () {
  Vue.prototype.$MAP_LOADED = true;
  app.$emit('initMap');
}

import 'bootstrap/dist/css/bootstrap.min.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import router from './router'
import './registerServiceWorker'


import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);
