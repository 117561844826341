<template>
    <div class="">
        <Range @error="$emit('error')" :user="userLocation" v-model="inRange" :map="map" v-if="map"></Range>
        <div class="d-flex" v-show="userLocation">
            <div>
                <a @click="menuWidth = 0" href="#"
                    class="h-100 btn btn-lg btn-secondary rounded-0 fs-3 d-flex align-items-center"><i
                        class="bi bi-list"></i></a>
            </div>
            <div class="flex-fill">
                <div v-if="(inRange && userLocation)" class=" alert alert-success m-0 rounded-0 h-100 fs-4">JESTEŚ W
                    STREFIE PARKU</div>
                <div v-if="(!inRange && userLocation)" class=" alert alert-danger m-0 rounded-0 h-100 fs-4">NIE JESTEŚ W
                    STREFIE PARKU </div>
                <div v-if="!userLocation" class=" alert alert-warning m-0 rounded-0 h-100 fs-4">
                    <div class="spinner-border" role="status">

                    </div>
                    OCZEKIWANIE NA LOKALIZACJE
                </div>

            </div>

        </div>
        <button v-show="!autorefresh" @click="resetToUser" class="btn btn-primary btn-fixed rounded-circle fs-4">
            <i class="bi bi-geo-alt-fill"></i>
        </button>
        <div class="sidenav" :style="'left:' + menuWidth + 'px'">
            <a href="javascript:void(0)" class="closebtn" @click="menuWidth = -300">&times;</a>
            <img class="p-4" src="@/assets/logo.png" width="280px" />
            <small>
                <a href="https://naturapark.eu/">Regulamin i polityka prywatności</a>
            </small>

        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Range from './Range.vue';
export default {
    name: "Location",
    props: ['map'],
    components: {
        Range
    },
    mounted() {
        this.locate();
        this.map.addListener('center_changed', this.mapDrag);
        this.map.addListener('zoom_changed', this.mapDrag);

    },
    computed: {
        token: function () {
            return localStorage.getItem('token');
        }
    },
    data: function () {
        return {
            marker: null,
            inRange: false,
            userLocation: null,
            autorefresh: true,
            menuWidth: -300,
            loaded: false,
        }
    },
    methods: {
        resetToUser: function () {
            this.map.setCenter(this.userLocation);
            this.map.setZoom(14);
            this.autorefresh = true;
        },
        mapDrag: function () {
            this.autorefresh = false;
        },
        sendLocation: function () {
            let vm = this;
            axios.post('https://quad.magico3.co.pl/quad/api/position', this.userLocation, { params: { token: this.token } }).then(response => {
                console.log(response);
            }, () => {
                vm.$emit('error');
                this.$swal({
                    icon: 'error',
                    title: 'Zostałeś wylogowany',
                    text: 'Twój dostęp starcił ważność lub zalogowano inne urządzenie na ten sam kod!',
                }).then(() => {
                    window.location.reload();
                });
            })
        },
        showMarker: function (pos) {
            if (!this.marker) {
                this.marker = new window.google.maps.Marker({
                    position: pos,
                    map: this.map,
                    icon: {
                        path: window.google.maps.SymbolPath.CIRCLE,
                        strokeColor: 'black',
                        scale: 7,
                        strokeWeight: 3,
                        fillColor: 'lime',
                        fillOpacity: 1,
                    }
                });
            } else {
                this.marker.setPosition(pos);
            }
        },
        locate: function () {
            if (navigator.geolocation) {
                navigator.geolocation.watchPosition(
                    (position) => {
                        console.log(position);
                        //document.getElementById('coords').innerHTML=JSON.stringify(position);
                        // const pos = {
                        //     lat: position.coords.latitude,
                        //     lng: position.coords.longitude,
                        // };
                        // if (window.google.maps.geometry.poly.containsLocation(pos, bermudaTriangle)) {
                        //     document.getElementById('alert').style.display = 'block';
                        // } else {
                        //     // color = 'red'
                        //     document.getElementById('alert').style.display = 'block';
                        //     document.getElementById('alert-body').style['background-color'] = 'red';
                        //     document.getElementById('alert-body').innerHTML = 'Nie jesteś w strefie PARKU'
                        // }
                        // marker.setPosition(pos);

                        // // infoWindow.setPosition(pos);
                        // // infoWindow.setContent("Location found.");
                        // // infoWindow.open(map);
                        // map.setCenter(pos);
                        this.userLocation = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };
                        this.sendLocation();
                        this.showMarker(this.userLocation);
                        if (this.autorefresh) {
                            this.resetToUser();
                        }
                    },
                    (e) => {
                        console.log('error', e);
                        if (e.code == 1) {
                            this.$swal({
                                icon: 'error',
                                title: 'Błąd lokalizacji',
                                text: 'Musisz udostępnić lokalizację w celu poprawnego działania aplikacji!',
                            }).then(() => {
                                window.location.reload();
                            });
                        }
                        //handleLocationError(true, infoWindow, map.getCenter()!);
                    }, {
                    enableHighAccuracy: true,
                    timeout: 30000,
                    maximumAge: 0
                }
                );
            } else {
                this.$swal({
                    icon: 'error',
                    title: 'Błąd lokalizacji',
                    text: 'Musisz udostępnić lokalizację w celu poprawnego działania aplikacji!',
                }).then(() => {
                    window.location.reload();
                });
                // Browser doesn't support Geolocation
                //handleLocationError(false, infoWindow, map.getCenter()!);
            }
        }
    }
}
</script>
<style>
/* .alert-fixed {
    position: fixed;
    bottom: 0px;
    width: 100%;
    padding: 20px;
} */
.btn-fixed {
    position: fixed;
    left: 10px;
    bottom: 100px;
}

.sidenav {
    height: 100%;
    /* 100% Full-height */
    width: 300px;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1000;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: -300px;
    background-color: whitesmoke;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */
}

/* The navigation menu links */

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */

.sidenav a:hover {
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}
</style>