<template>
  <div class="d-flex h-100 flex-column" id="app">
    <div v-show="logged" id="map">
    </div>
    <Location @error="tokenRemove" v-if="(map && logged)" :map="map"></Location>
    <Login @login="setToken" v-if="!logged"></Login>
  </div>
</template>

<script>
import Login from '../components/Login.vue'
import Location from '../components/Location.vue'

export default {
  name: 'App',
  components: {
    Login,
    Location,
  },
  mounted() {
    //window.Notification.requestPermission();
    console.log(this.$MAP_LOADED);
    if (this.$route.params.pin) {
      localStorage.removeItem('token');
    }
    this.checkLogged();
    //this.initMap();
  },
  data: function () {
    return {
      logged: false,
      map: null,
    }
  },
  computed: {
    token: function () {
      return localStorage.getItem('token');
    }
  },
  methods: {
    tokenRemove: function () {
      if (window.navigator.onLine) {
        this.$swal({  
          icon: 'error',
          title: 'Wylogowano',
          text: 'Zostałeś wylogowany z aplikacji!',
        })
        localStorage.removeItem('token');
        this.checkLogged();
      }
    },
    setToken: function (token) {
      localStorage.setItem('token', token);
      this.checkLogged();
    },

    checkLogged: function () {
      this.logged = localStorage.getItem('token') ? true : false;
      if (this.logged && !this.map) {
        this.initMap();
      }
      if (this.$route.params.pin) {
        this.$router.push('/');
      }
    },
    initMap: function () {
      //const uluru = { lat: 49.309260, lng: 22.330720 };
      const uluru = {
        lat: 50.570961,
        lng: 22.061590
      };

      // The map, centered at Uluru
      this.map = new window.google.maps.Map(document.getElementById("map"), {
        zoom: 14,
        center: uluru,
        mapTypeId: 'satellite',
        streetViewControl: false,
      });
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

#map {
  height: 100vh;
  width: 100%
}

body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: whitesmoke !important;
}

.container {
  max-width: 540px !important;
  margin: auto;
  background-color: white;
}

.h-100 {
  height: 100vh;
}
</style>
