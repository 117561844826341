<template>
    <div>

    </div>
</template>
<script>
import axios from 'axios';
export default {
    name: "Range",
    props: ['map', 'user', 'value'],
    mounted() {
        this.getRange();
        this.createPolygon();
    },
    computed: {
        token: function () {
            return localStorage.getItem('token');
        }
    },
    watch: {
        user: function () {
            if (this.user && this.polygon) {
                let isIn = !window.google.maps.geometry.poly.containsLocation(this.user, this.polygon)
                this.$emit('input', isIn);
                if (!isIn && Date.now()- this.lastAlarm >10000) {
                    let audio = new Audio('alarm.mp3');
                    audio.play();
                    this.lastAlarm = Date.now();
                }
            }
        }
    },
    data: function () {
        return {
            polygon: null,
            lastAlarm: 0,
        }
    },
    methods: {
        isInLocation: function () {

        },
        getRange: function () {
            let vm = this;
            axios.get('https://quad.magico3.co.pl/quad/api/zone', { params: { 'token': this.token } }).then(response => {
                console.log(response);
                vm.createPolygon(response.data.quad_zone);
            }, () => {
                vm.$emit('error')
            })
        },
        createPolygon: function (cords) {
            var everythingElse = [
                new window.google.maps.LatLng(0, -90),
                new window.google.maps.LatLng(0, 90),
                new window.google.maps.LatLng(90, 90),
                new window.google.maps.LatLng(90, -90),
            ];


            console.log(cords);
            this.polygon = new window.google.maps.Polygon({
                paths: [everythingElse, cords.reverse()],
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 1.5,
                fillColor: "#FF0000",
                fillOpacity: 0.1,

            });
            this.polygon.setMap(this.map);
        },
    }
}
</script>