<template>
    <div class="container h-100 d-flex flex-column justify-content-start">
        <div class="d-flex justify-content-center mb-1 mt-5">
            <img class="p-4" src="@/assets/logo.png" width="280px" />
        </div>
        <div class="mt-2 pt-5">
            <h1>Wynajem QUADU</h1>

        </div>
        <div class=" mb-5">
            <label>PIN Kierowcy</label>
            <input v-model="pin" class="form-control form-control-lg" placeholder="Podaj swój PIN logowania"
                type="tel" />
        </div>
        <button @click="login" class="btn btn-success btn-lg mb-4">JADĘ!</button>

        <small>
            <a href="https://naturapark.eu/">Regulamin i polityka prywatności</a>
        </small>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: "Login",
    data: function () {
        return {
            pin: '',
        }
    },
    mounted: function () {
        console.log(this.$route.params);
        if (this.$route.params.pin) {
            this.pin = this.$route.params.pin;
            this.login();
        }
    },
    methods: {
        login: function () {
            //  let vm = this;
            axios.post('https://quad.magico3.co.pl/quad/api/login', { 'code': this.pin }).then(response => {
                console.log(response);
                this.$emit('login', response.data.token);
            }, (error) => {
                if (error.response.data.code == 408) {
                    this.$swal({
                        icon: 'error',
                        title: 'Nie ważny PIN',
                        text: 'Kod PIN starcił ważność!',
                    })
                } else {
                    this.$swal({
                        icon: 'error',
                        title: 'Niepoprawny pin',
                        text: 'Kod PIN jest niepoprawny!',
                    })
                }
                this.pin = '';
                console.log(error);
            })
        }
    }

}
</script>
<style>

</style>